import { classnames } from '@tailwindcss-classnames';

const lightWhite = classnames('text-white');
const lightGrey = classnames('text-nzxt-light-grey-800');
const darkGrey = classnames('text-white');
const blockWrapper = classnames('lg:max-w-xl', 'lg:mx-auto');

export const iconBlockwrapper = (noContentWrapper: boolean): string =>
  classnames('flex', 'text-left', 'lg:justify-start', {
    [blockWrapper]: !noContentWrapper,
  });
export const iconWrapper = classnames('mr-6', 'xl:mr-8', 'flex-shrink-0');

const defaultSize = classnames('h-14', 'w-14');
const smallSize = classnames('h-12', 'w-12');

export const icon = (isInverted: boolean, isSmall: boolean): string =>
  classnames('fill-current', {
    [lightWhite]: isInverted,
    [smallSize]: isSmall,
    [defaultSize]: !isSmall,
  });

export const iconBlockHeading = (isInverted: boolean): string =>
  classnames('text-base', 'xl:text-lg', 'font-semibold', {
    [lightWhite]: isInverted,
  });
export const iconBlockMDXWrapper = (isInverted: boolean): string =>
  classnames('mt-1', 'text-base', {
    [lightGrey]: !isInverted,
    [darkGrey]: isInverted,
  });

const centerHeading = classnames('flex', 'flex-col', 'justify-center');
export const iconBlockContentWrapper = (hasAllContent: boolean): string =>
  classnames({
    [centerHeading]: !hasAllContent,
  });
