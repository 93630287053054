import { classnames } from '@tailwindcss-classnames';

// Container Styles
export const baseGridItem = classnames(
  'relative',
  'col-span-12',
  'flex',
  'w-full',
  'overflow-hidden',
  'h-full'
);
const fullGridItem = classnames(baseGridItem, 'md:col-span-12');
const seventyGridItem = classnames(
  baseGridItem,
  'md:col-span-7',
  'lg:col-span-8'
);
const sixtyGridItem = classnames(baseGridItem, 'md:col-span-7');
const fiftyGridItem = classnames(baseGridItem, 'lg:col-span-6');
const fortyGridItem = classnames(baseGridItem, 'md:col-span-5');
const thirtyGridItem = classnames(
  baseGridItem,
  'md:col-span-5',
  'lg:col-span-4'
);
const thirtyThreeGridItem = classnames(baseGridItem, 'md:col-span-4');

export const gridItems = {
  '100%': fullGridItem,
  '70%': seventyGridItem,
  '60%': sixtyGridItem,
  '50%': fiftyGridItem,
  '40%': fortyGridItem,
  '30%': thirtyGridItem,
  '33%': thirtyThreeGridItem,
};

const shortCard = classnames('min-h-260');
const defaultCard = classnames('min-h-400', 'lg:min-h-500', 'xl:min-h-600');
export const gridHeight = (isShort: boolean): string =>
  classnames({
    [shortCard]: isShort,
    [defaultCard]: !isShort,
  });

// Text Block Styles
const defaultTextColor = classnames('text-nzxt-light-grey-800');
const invertedTextColor = classnames('text-white');
export const textColors = (isInverted: boolean): string =>
  classnames({
    [invertedTextColor]: isInverted,
    [defaultTextColor]: !isInverted,
  });

const verticalMiddle = classnames('my-auto');
const verticalTop = classnames('mb-auto');
const verticalBottom = classnames('mt-auto');
export const verticalCopy = {
  middle: verticalMiddle,
  top: verticalTop,
  bottom: verticalBottom,
};

const horizontalMiddle = classnames('mx-auto');
const horizontalLeft = classnames('mr-auto', 'ml-0');
const horizontalRight = classnames('ml-auto', 'mr-auto', 'md:mr-0');

export const horizontalCopy = {
  center: horizontalMiddle,
  left: horizontalLeft,
  right: horizontalRight,
};
const textBase = classnames('relative');

const largePadding = classnames(
  'p-8',
  'xl:px-24',
  '2xl:px-32',
  'xl:py-12',
  '2xl:py-16',
  textBase
);
const smallPadding = classnames('p-8', textBase);

export const textPadding = {
  '100%': largePadding,
  '70%': smallPadding,
  '60%': smallPadding,
  '50%': smallPadding,
  '40%': smallPadding,
  '33%': smallPadding,
  '30%': smallPadding,
};

export const textMaxWidthFullCard = classnames(
  'max-w-full',
  'md:max-w-sm',
  'lg:max-w-lg',
  'xl:max-w-2xl'
);
const textMaxWidthMdCard = classnames('max-w-full', 'md:max-w-sm');
const textMaxWidthSmMdCard = classnames('max-w-full', 'md:max-w-lg');
const textMaxWidthSmCard = classnames('max-w-full', 'md:max-w-sm');

export const textWidths = {
  '100%': textMaxWidthFullCard,
  '60%': textMaxWidthMdCard,
  '50%': textMaxWidthSmMdCard,
  '40%': textMaxWidthSmCard,
  '33%': textMaxWidthSmCard,
  '30%': textMaxWidthSmCard,
};

// Image Styles
export const imageWrapper = classnames(
  'w-full',
  'max-w-sm',
  'md:max-w-xs',
  'lg:max-w-sm',
  'xl:max-w-lg',
  '2xl:max-w-xl'
);

export const imageWrapperFull = classnames('w-full');
export const imageFullHFullW = classnames('max-w-xl', 'xl:max-w-3xl');
export const position = {
  left: classnames('md:absolute'),
  right: classnames('md:absolute'),
  center: classnames('relative'),
  middle: classnames('relative'),
  top: classnames('md:absolute'),
  'y axis': classnames('md:absolute'),
  'x axis': classnames('md:absolute'),
};

const topPosition = classnames('top-0');
const centerPosition = classnames('inset-0', 'absolute');
export const backgroundImage = (
  isCenter: string,
  hasSmallBackgroundAsset: boolean
): string =>
  classnames(
    'w-full',
    '!absolute',
    {
      'bottom-0': !hasSmallBackgroundAsset,
    },
    {
      [topPosition]: isCenter !== 'center',
      [centerPosition]: isCenter === 'center',
    }
  );

export const bottomImage = classnames('bottom-0', 'mt-auto');
const topImage = classnames('top-0', 'mb-auto');
const yImage = classnames('top-0', 'bottom-0');
const centerImage = classnames(
  'flex',
  'my-auto',
  'justify-center',
  'items-center',
  'md:transform',
  'md:-translate-y-1/2',
  'md:top-1/2'
);
export const centerStacked = classnames(
  'flex',
  'my-auto',
  'justify-center',
  'items-center'
);

export const imagePositionVertical = {
  bottom: bottomImage,
  top: topImage,
  center: centerImage,
  'y axis': yImage,
};

export const imagePositionVerticalStacked = {
  bottom: bottomImage,
  top: topImage,
  center: centerStacked,
  'y axis': yImage,
};

const rightImage = classnames('right-0', 'ml-auto');
const leftImage = classnames('left-0', 'mr-auto');
const xImage = classnames('left-0', 'right-0');
const middleImageStacked = classnames(
  'flex',
  'mx-auto',
  'justify-center',
  'items-center'
);
const middleImage = classnames(
  'flex',
  'mx-auto',
  'justify-center',
  'items-center',
  'md:transform',
  'md:-translate-x-1/2',
  'md:left-1/2'
);
export const centerImageMode = classnames(
  'transform',
  '-translate-x-1/2',
  'left-1/2'
);

export const imagePositionHorizontal = {
  right: rightImage,
  left: leftImage,
  middle: middleImage,
  'x axis': xImage,
};

export const imagePositionHorizontalStacked = {
  right: rightImage,
  left: leftImage,
  middle: middleImageStacked,
  'x axis': xImage,
};

// Content Styles
export const orderOne = classnames('order-1');
export const orderTwo = classnames('order-2');
export const baseCol = classnames('col-span-2');
export const rounded = classnames('rounded-md');

const fullbleedMaxWidth = classnames('max-w-[1536px]', 'mx-auto');
const defaultWidth = classnames('w-full');
export const maxContentWidth = (isFullbleed: boolean): string =>
  classnames({
    [fullbleedMaxWidth]: isFullbleed,
    [defaultWidth]: !isFullbleed,
  });

const top = classnames('flex-col');
const bottom = classnames('flex-col');
const right = classnames('flex-col', 'md:flex-row', 'items-end');
const left = classnames('flex-col', 'md:flex-row');
export const gridCell = {
  top,
  bottom,
  right,
  left,
};
