import { classnames } from '@tailwindcss-classnames';

const defaultContainer = classnames(
  'w-11/12',
  'lg:max-w-screen-lg',
  'xl:max-w-screen-xl',
  '2xl:max-w-screen-2xl',
  'xl:px-20'
);

export const container = (isFullBleed: boolean): string =>
  classnames(
    'mx-auto',
    'grid',
    'grid-cols-12',
    'gap-4',
    'md:gap-3',
    'lg:gap-5',
    {
      [defaultContainer]: !isFullBleed,
    }
  );
export const headingWrapper = classnames('col-span-12');
