import { classnames } from '@tailwindcss-classnames';

const darkPlay = classnames('text-nzxt-light-grey-700');
const lightPlay = classnames('text-nzxt-light-grey-300');
export const svgStyles = (isInverted: boolean): string =>
  classnames('h-10', 'w-10', 'stroke-current', {
    [darkPlay]: isInverted,
    [lightPlay]: !isInverted,
  });
export const buttonPosition = classnames('absolute', 'bottom-10', 'left-10');
