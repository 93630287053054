/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo } from 'react';
import * as styles from './styles';

const Table: FC<any> = props => (
  <div className={styles.wrapper}>
    <table {...props} />
  </div>
);

export default memo(Table);
