/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import HydrateMdx from '@components/HydrateMdx';
import cx from 'clsx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import IconDynamic from '@components/IconDynamic';
import listStyles from './listStyles.module.css';

import * as styles from './styles';

type Props = {
  heading?: string;
  iconName?: string;
  decoratedMarkdownContent?: MDXRemoteSerializeResult;
  darkTheme?: boolean;
  smallIcon?: boolean;
  noContentWrapper?: boolean;
};

const IconBlock: FC<Props> = ({
  heading,
  iconName,
  decoratedMarkdownContent,
  smallIcon = false,
  darkTheme = false,
  noContentWrapper = false,
}) => (
  <div className={styles.iconBlockwrapper(noContentWrapper)}>
    {iconName && (
      <div className={styles.iconWrapper}>
        <IconDynamic
          iconName={iconName}
          className={styles.icon(darkTheme, smallIcon)}
        />
      </div>
    )}
    <div
      className={styles.iconBlockContentWrapper(
        Boolean(decoratedMarkdownContent)
      )}
    >
      {heading && (
        <h4 className={styles.iconBlockHeading(darkTheme)}>{heading}</h4>
      )}
      {decoratedMarkdownContent && (
        <div
          className={cx(styles.iconBlockMDXWrapper(darkTheme), listStyles.list)}
        >
          <HydrateMdx mdx={decoratedMarkdownContent} />
        </div>
      )}
    </div>
  </div>
);
export default memo(IconBlock);
