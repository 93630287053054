import { classnames } from '@tailwindcss-classnames';

const base = classnames(
  'flex',
  'flex-wrap',
  'gap-4',
  'md:gap-7',
  'max-w-max',
  'items-center'
);
export const buttonContainer = {
  left: classnames(base),
  center: classnames(base, 'justify-center'),
};

export const textSize = classnames('md:text-lg');
