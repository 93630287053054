/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import type { ContentGridItem } from '@framework/api/types';
import GridItem from '../GridItem';

type Props = {
  cards: ContentGridItem[];
  fullBleed?: boolean;
  itemColumnSpan?: string;
};

const Row: FC<Props> = ({ cards, fullBleed, itemColumnSpan }) => {
  const trimmedCards =
    cards?.length > 0 && cards.slice(0, Object.keys(itemColumnSpan)?.length);

  return trimmedCards?.length > 0 ? (
    <>
      {trimmedCards?.map((item, i) => (
        <GridItem
          {...item}
          key={item?.id}
          itemColumnSpan={itemColumnSpan[i]}
          fullBleed={fullBleed}
        />
      ))}
    </>
  ) : null;
};

export default memo(Row);
