/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { FC, memo, useMemo } from 'react';
import cx from 'clsx';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import HydrateMdx from '@components/HydrateMdx';
import NextImage from 'next/legacy/image';
import type {
  DatoProductImage,
  ButtonGroup,
  MarkdownIconBlocks,
} from '@framework/api/types';
import Image from '@components/Image';
import LinkGroup from '@components/LinkGroup';
import IconDynamic from '@components/IconDynamic';
import listStyles from './listStyles.module.css';
import IconBlock from './IconBlock';
import * as styles from './styles';

interface Props {
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  buttons?: ButtonGroup[];
  isCenter?: boolean;
  isInverted?: boolean;
  icon?: string;
  customTheme?: string;
  copy?: string;
  decoratedCopy?: MDXRemoteSerializeResult;
  markdownIconBlocks?: MarkdownIconBlocks[];
}

const TextBlock: FC<Props> = ({
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  buttons,
  isCenter,
  isInverted,
  icon,
  customTheme,
  markdownIconBlocks,
  decoratedCopy,
  copy,
}) => {
  const sanitizedCustomTheme = customTheme?.toLowerCase() || 'default';
  const hasCustomTheme = sanitizedCustomTheme !== 'default';

  const composedClassNameCopy = useMemo(
    () => cx({ [styles.textCenter]: isCenter }, styles.subheading),
    [isCenter]
  );
  const composedClassNameHeading = useMemo(
    () => cx(styles.heading, { [styles.textCenter]: isCenter }),
    [isCenter]
  );
  const composedClassNameEyebrow = useMemo(
    () =>
      cx({ [styles.textCenter]: isCenter }, styles.eyebrow, {
        [styles.customThemes[sanitizedCustomTheme]]: hasCustomTheme,
      }),
    [isCenter, sanitizedCustomTheme, hasCustomTheme]
  );

  return heading || eyebrow || subheading ? (
    <>
      {(eyebrowLogo?.nonResponsiveUrl || eyebrowLogo?.responsiveImage) && (
        <>
          {eyebrowLogo?.responsiveImage ? (
            <div className={composedClassNameEyebrow}>
              <Image
                data={eyebrowLogo?.responsiveImage}
                width={eyebrowLogo?.responsiveImage?.width}
                height={eyebrowLogo?.responsiveImage?.height}
                style={{ maxWidth: '200px' }}
              />
            </div>
          ) : (
            <div
              className={composedClassNameEyebrow}
              style={{ maxWidth: '200px' }}
            >
              <NextImage
                src={eyebrowLogo?.nonResponsiveUrl}
                width={eyebrowLogo?.width}
                height={eyebrowLogo?.height}
              />
            </div>
          )}
        </>
      )}
      {eyebrow && <p className={composedClassNameEyebrow}>{eyebrow}</p>}
      {icon && (
        <div className={composedClassNameEyebrow}>
          <IconDynamic
            iconName={icon}
            className={styles.iconStyles(isCenter)}
          />
        </div>
      )}
      {heading && (
        <h3
          className={composedClassNameHeading}
          data-test-id="content-grid-text-block-heading"
        >
          {heading}
        </h3>
      )}
      {subheading && <div className={composedClassNameCopy}>{subheading}</div>}
      {(decoratedCopy || copy) && (
        <div className={cx(styles.copy(isInverted, isCenter), listStyles.list)}>
          {decoratedCopy ? <HydrateMdx mdx={decoratedCopy} /> : copy}
        </div>
      )}

      {Array.isArray(markdownIconBlocks) && markdownIconBlocks?.length > 0 && (
        <div className={styles.markdownIconBlocksWrapper}>
          {markdownIconBlocks?.map(item => (
            <IconBlock
              heading={item.heading}
              decoratedMarkdownContent={
                item?.markdownCopy && item.decoratedMarkdownContent
              }
              iconName={item.iconName}
              key={item.id}
              darkTheme={isInverted}
              smallIcon
            />
          ))}
        </div>
      )}

      {Array.isArray(buttons) && buttons?.length > 0 && (
        <div className={styles.buttonContainer(isCenter)}>
          <LinkGroup
            links={buttons}
            isInverted={isInverted}
            alignment="center"
          />
        </div>
      )}
    </>
  ) : null;
};

export default memo(TextBlock);
