/* eslint-disable no-underscore-dangle */
import { FC, memo, useRef, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import useIntersectionObserver from '@utils/hooks/useIntersectionObserver';
import PlayPause from '@components/VideoCarousel/PlayPause';
import * as styles from './styles';

interface Props {
  videoUrl: string;
  videoPoster: string;
  videoClasses?: string;
  isInverted?: boolean;
}

const PrimaryVideo: FC<Props> = ({
  videoUrl,
  videoPoster,
  videoClasses,
  isInverted = false,
}) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const observedEl = useIntersectionObserver(ref, {});
  const isVisible = !!observedEl?.isIntersecting;
  const [isPlaying, setIsPlaying] = useState(true);

  const clickToPlay = (target: HTMLVideoElement): void => {
    const playPromise = target?.play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          setIsPlaying(true);
          if (isPlaying) {
            setIsPlaying(false);
            target?.pause();
          }
        })
        .catch(error => {
          throw error;
          Sentry.captureException(error);
        });
    }
  };

  useEffect(() => {
    if (ref?.current) {
      const playPromise = ref.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            if (!isVisible) {
              ref.current.pause();
            }
          })
          .catch(err => {
            Sentry.captureException(err);
            throw err;
          });
      }
    }
  }, [isVisible]);

  return videoUrl ? (
    <>
      <video
        ref={ref}
        loop
        muted
        playsInline
        src={videoUrl}
        poster={videoPoster}
        className={videoClasses}
      />
      <button
        onClick={() => clickToPlay(ref?.current)}
        type="button"
        className={styles.buttonPosition}
      >
        <PlayPause
          isPlaying={isPlaying}
          svgStyles={styles.svgStyles(isInverted)}
        />
      </button>
    </>
  ) : null;
};

export default memo(PrimaryVideo);
