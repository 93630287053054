import { classnames } from '@tailwindcss-classnames';

export const heading = classnames(
  'text-2xl',
  'lg:text-3xl',
  'font-bold',
  'mt-1',
  'leading-6',
  'tracking-tighter'
);
export const eyebrow = classnames(
  'text-sm',
  'font-semibold',
  'leading-4',
  'mb-2'
);
export const eyebrowLogo = classnames(
  'w-20',
  'xl:w-24',
  'mx-auto',
  'lg:mx-0',
  'mt-2',
  'lg:mx-0'
);
export const subheading = classnames('mt-2', 'mb-4', 'text-lg');
export const textWhite = classnames('text-white');

export const textCenter = classnames(
  'text-center',
  'mx-auto',
  'flex',
  'justify-center'
);

export const centerCopy = classnames('text-center', 'mx-auto');
export const centerPlacement = classnames(
  'flex',
  'items-center',
  'justify-center',
  'mx-auto',
  'text-center'
);
export const iconStyles = (isCenter: boolean): string =>
  classnames('w-12', 'h-12', 'fill-current', 'mb-2', {
    [centerPlacement]: isCenter,
  });

export const buttonContainer = (isCenter: boolean): string =>
  classnames('mt-4', { [centerPlacement]: isCenter });

export const customThemes = {
  green: classnames('text-nzxt-green-400'),
  blue: classnames('text-nzxt-blue-300'),
  red: classnames('text-nzxt-red-300'),
  intel: classnames('text-intel'),
  nvidia: classnames('text-nvidia'),
  amd: classnames('text-amd'),
  yellow: classnames('text-nzxt-yellow-150'),
};
export const markdownIconBlocksWrapper = classnames('my-4', 'space-y-3');

export const copy = (isInverted: boolean, isCenter: boolean): string =>
  classnames('mb-4', {
    [centerCopy]: isCenter,
    [textWhite]: isInverted,
  });
