import { FC, useMemo, memo } from 'react';
import { MDXRemote } from 'next-mdx-remote';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import Table from './Components/Table';

type Props = {
  mdx: MDXRemoteSerializeResult;
  components?: Record<string, unknown>;
};

const HydrateMdx: FC<Props> = ({ mdx, components = {} }) => {
  const mdxComponents = useMemo(() => {
    const standardComponents = {
      table: Table,
    };

    return { ...standardComponents, ...components };
  }, [components]);

  return <MDXRemote {...mdx} components={mdxComponents} />;
};

export default memo(HydrateMdx);
