/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import { FC, memo, useMemo } from 'react';
import cx from 'clsx';
import { useMedia } from '@utils/hooks';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote';
import type {
  DatoProductImage,
  ButtonGroup,
  MarkdownIconBlocks,
} from '@framework/api/types';
import Image from '@components/Image';
import PrimaryVideo from '../PrimaryVideo';
import TextBlock from '../TextBlock';

import * as styles from './styles';

type Props = {
  isInverted?: boolean;
  shortCard?: boolean;
  itemColumnSpan?: string;
  theme?: string;
  contentArrangement?: string;
  eyebrowLogo?: DatoProductImage;
  eyebrow?: string;
  heading?: string;
  subheading?: string;
  copyVerticalPosition?: string;
  copyHorizontalPosition?: string;
  buttons?: ButtonGroup[];
  backgroundColor?: { hex?: string };
  largeBackgroundAsset?: DatoProductImage;
  smallBackgroundAsset?: DatoProductImage;
  largePrimaryAsset?: DatoProductImage;
  smallPrimaryAsset?: DatoProductImage;
  centerText?: boolean;
  icon?: string;
  fullBleed?: boolean;
  primaryAssetHorizontalAlignment?: string;
  primaryAssetVerticalAlignment?: string;
  backgroundAlignment?: string;
  primaryAssetFullBleed?: boolean;
  copy?: string;
  decoratedCopy?: MDXRemoteSerializeResult;
  primaryVideo?: {
    video: { mp4Url: string };
    thumbnailUrl: string;
  };
  markdownIconBlocks?: MarkdownIconBlocks[];
};

const GridItem: FC<Props> = ({
  isInverted,
  shortCard,
  itemColumnSpan,
  theme,
  contentArrangement,
  eyebrowLogo,
  eyebrow,
  heading,
  subheading,
  copyVerticalPosition,
  copyHorizontalPosition,
  buttons,
  backgroundColor,
  largeBackgroundAsset,
  smallBackgroundAsset,
  smallPrimaryAsset,
  largePrimaryAsset,
  primaryAssetVerticalAlignment,
  primaryAssetHorizontalAlignment,
  centerText,
  icon,
  fullBleed,
  backgroundAlignment,
  primaryAssetFullBleed,
  primaryVideo,
  copy,
  decoratedCopy,
  markdownIconBlocks,
}) => {
  const isSm = useMedia('sm');
  const sanitizedVerticalCopyAlignment =
    copyVerticalPosition?.toLowerCase() || 'middle';
  const sanitizedHorizontalCopyAlignment =
    copyHorizontalPosition?.toLowerCase() || 'center';
  const sanitizedPrimaryImagePosition =
    primaryAssetVerticalAlignment?.toLowerCase() || 'center';
  const sanitizedPrimaryImageHorizontal =
    primaryAssetHorizontalAlignment?.toLowerCase() || 'center';
  const sanitizedBackgroundAlignment =
    backgroundAlignment?.toLowerCase() || 'top';
  const sanitizedContentArrangement =
    contentArrangement?.toLowerCase() || 'left';

  const isStacked = Boolean(
    sanitizedContentArrangement === 'top' ||
      sanitizedContentArrangement === 'bottom'
  );
  const isOrderOne = Boolean(
    sanitizedContentArrangement === 'top' ||
      sanitizedContentArrangement === 'left'
  );

  // Container Styles
  const composedGridItemWrapper = useMemo(
    () =>
      cx(
        itemColumnSpan
          ? styles.gridItems[itemColumnSpan || '100%']
          : styles.baseGridItem,
        styles.gridHeight(shortCard),
        styles.gridCell[sanitizedContentArrangement],
        {
          [styles.rounded]: !fullBleed,
        }
      ),
    [itemColumnSpan, shortCard, sanitizedContentArrangement, fullBleed]
  );

  // Text Block Styles
  const composedTextBlockWrapper = useMemo(
    () =>
      cx(
        fullBleed
          ? styles.textMaxWidthFullCard
          : styles.textWidths[itemColumnSpan || '100%'],
        isOrderOne ? styles.orderOne : styles.orderTwo,
        styles.textColors(isInverted),
        styles.verticalCopy[sanitizedVerticalCopyAlignment],
        fullBleed
          ? styles.horizontalCopy.center
          : styles.horizontalCopy[sanitizedHorizontalCopyAlignment],
        styles.textPadding[itemColumnSpan || '100%']
      ),

    [
      isInverted,
      isOrderOne,
      sanitizedVerticalCopyAlignment,
      itemColumnSpan,
      sanitizedHorizontalCopyAlignment,
      fullBleed,
    ]
  );

  // Image Block Styles
  const composedImageWrapper = useMemo(
    () =>
      cx(
        isOrderOne ? styles.orderTwo : styles.orderOne,
        isSm
          ? styles.imagePositionVertical.bottom
          : isStacked || sanitizedPrimaryImagePosition === 'center'
          ? styles.imagePositionVerticalStacked[sanitizedPrimaryImagePosition]
          : styles.imagePositionVertical[sanitizedPrimaryImagePosition],
        isSm
          ? styles.imagePositionHorizontal.middle
          : isStacked || sanitizedPrimaryImageHorizontal === 'middle'
          ? styles.imagePositionHorizontalStacked[
              sanitizedPrimaryImageHorizontal
            ]
          : styles.imagePositionHorizontal[sanitizedPrimaryImageHorizontal],
        styles.position[sanitizedPrimaryImageHorizontal],
        primaryAssetFullBleed
          ? styles.imageWrapperFull
          : fullBleed
          ? styles.imageWrapperFull
          : styles.imageWrapper
      ),
    [
      sanitizedPrimaryImagePosition,
      sanitizedPrimaryImageHorizontal,
      fullBleed,
      primaryAssetFullBleed,
      isSm,
      isStacked,
      isOrderOne,
    ]
  );

  // Image styles
  const composedImage = useMemo(
    () =>
      cx(
        sanitizedPrimaryImagePosition === 'y axis'
          ? styles.imageFullHFullW
          : null,
        sanitizedPrimaryImagePosition === 'y axis'
          ? styles.imagePositionHorizontal[sanitizedPrimaryImageHorizontal]
          : null
      ),
    [sanitizedPrimaryImagePosition, sanitizedPrimaryImageHorizontal]
  );

  const background = backgroundColor?.hex
    ? { backgroundColor: backgroundColor?.hex }
    : undefined;

  const primaryImage = isSm
    ? smallPrimaryAsset?.responsiveImage || largePrimaryAsset?.responsiveImage
    : largePrimaryAsset?.responsiveImage;
  const backgroundImage = isSm
    ? smallBackgroundAsset?.responsiveImage ||
      largeBackgroundAsset?.responsiveImage
    : largeBackgroundAsset?.responsiveImage;

  return (
    <div className={composedGridItemWrapper} style={{ ...background }}>
      {backgroundImage && (
        <div
          className={cx(
            styles.backgroundImage(
              sanitizedBackgroundAlignment,
              Boolean(smallBackgroundAsset)
            )
          )}
        >
          <Image
            data={backgroundImage}
            width={backgroundImage?.width}
            height={backgroundImage?.height}
            className="h-full object-cover"
          />
        </div>
      )}
      <div
        className={cx(
          composedGridItemWrapper,
          styles.maxContentWidth(fullBleed)
        )}
      >
        {primaryVideo?.video?.mp4Url ? (
          <div className={composedImageWrapper}>
            <PrimaryVideo
              videoClasses={composedImage}
              videoUrl={primaryVideo?.video?.mp4Url}
              videoPoster={primaryVideo?.thumbnailUrl}
              isInverted={isInverted}
            />
          </div>
        ) : (
          <>
            {primaryImage && (
              <div className={composedImageWrapper}>
                <Image
                  data={primaryImage}
                  width={primaryImage?.width}
                  height={primaryImage?.height}
                  className={composedImage}
                />
              </div>
            )}
          </>
        )}

        <div className={composedTextBlockWrapper}>
          <TextBlock
            eyebrowLogo={eyebrowLogo}
            eyebrow={eyebrow}
            heading={heading}
            subheading={subheading}
            buttons={buttons}
            isInverted={isInverted}
            isCenter={centerText || fullBleed}
            icon={icon}
            customTheme={theme}
            copy={copy}
            decoratedCopy={decoratedCopy}
            markdownIconBlocks={markdownIconBlocks}
          />
        </div>
      </div>
    </div>
  );
};
export default memo(GridItem);
