/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import type { ContentGridItem } from '@framework/api/types';
import { useRouter } from 'next/router';
import SectionWrapper from '@components/SectionWrapper';
import SectionHeading from '@components/SectionHeading';
import slugify from '@utils/slugify';
import Row from './Row';
import * as styles from './styles';

export type Props = {
  id?: string | number;
  heading?: string;
  subheading?: string;
  headingAlignment?: 'Left' | 'Center';
  fullBleed?: boolean;
  rows: { cards: ContentGridItem[]; id?: string; rowSplit?: string }[];
  noWrapperSpacing?: boolean;
  variableSpacing?: string;
};

const fiftyFifty = { 0: '50%', 1: '50%' };
const fortySixty = { 0: '40%', 1: '60%' };
const sixtyForty = { 0: '60%', 1: '40%' };
const thirtySeventy = { 0: '30%', 1: '70%' };
const seventyThirty = { 0: '70%', 1: '30%' };
const thirtyThree = { 0: '33%', 1: '33%', 2: '33%' };
const oneHundred = { 0: '100%' };
const rowSplitMap = {
  '100': oneHundred,
  '50/50': fiftyFifty,
  '40/60': fortySixty,
  '60/40': sixtyForty,
  '30/70': thirtySeventy,
  '70/30': seventyThirty,
  '33/33/33': thirtyThree,
};

const ContentGrid: FC<Props> = ({
  heading,
  subheading,
  fullBleed,
  headingAlignment,
  rows,
  noWrapperSpacing = false,
  variableSpacing,
}) => {
  const hasItems = Array.isArray(rows) && rows?.length > 0;
  const { locale } = useRouter();

  const jumboMaxWidth = fullBleed
    ? {
        maxWidth: '2560px',
      }
    : undefined;

  return hasItems ? (
    <SectionWrapper
      id={slugify(heading)}
      noSpacing={noWrapperSpacing}
      data-test-id="content-grid-block"
      variableSpacing={variableSpacing}
      className="scroll-m-20"
    >
      <div className={styles.container(fullBleed)} style={jumboMaxWidth}>
        {heading || subheading ? (
          <div className={styles.headingWrapper}>
            <SectionHeading
              textPosition={headingAlignment}
              heading={heading}
              subheading={subheading}
              lang={locale}
            />
          </div>
        ) : null}
        {rows?.map(row => (
          <Row
            {...row}
            key={row?.id}
            fullBleed={fullBleed}
            itemColumnSpan={
              rowSplitMap[row.rowSplit] && rowSplitMap[row.rowSplit]
            }
          />
        ))}
      </div>
    </SectionWrapper>
  ) : null;
};
export default memo(ContentGrid);
