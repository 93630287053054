/* eslint-disable max-len */
import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';

type Props = {
  isPlaying?: boolean;
  svgStyles?: string;
};

const PlayPause: FC<Props> = ({ isPlaying, svgStyles }) => {
  const PLAY_LABEL = t('play_label');
  const PAUSE_LABEL = t('pause_label');

  return !isPlaying ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={svgStyles}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <title>{PLAY_LABEL}</title>
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={svgStyles}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <title>{PAUSE_LABEL}</title>
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default memo(PlayPause);
